import Api, {Io, Response} from './api';
import config from '../config';
import {Observable, Observer, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';

export type UserPermission = {
  userId: number;
  permission: string;
  field: string | null;
  key: string | null;
}

export type UserVariable = {
  userId: number;
  variable: string;
  field: string | null;
}

export type User = {
    username: string;
     name: string | null;
    picture: string | null;
     password: string;
     provisoryPassword: string | null;
     email: string | null;
     emailValidated: boolean;
     phone: string | null;
     phoneValidated: boolean;
     Permissions: UserPermission[];
     Variables: UserVariable[];
    PostosColeta ?: any;
    PostoColetaUsers: any[];
};

@Injectable()
export default class Me {
    constructor(public api: Api) {
    }

    data: Subject<User | false> = new Subject();

    private async getCacheData(): Promise<User | false>{
        try{
            return JSON.parse(await localStorage.getItem(config.me.cache_name));
        }catch (e) {
            return false;
        }
    }

    getData(){
        this.get(false).subscribe(data => {console.log('l')});
        return this;
    }

    get(cache = true, io ?: Io): Observable<User | false>{
        const self = this;

        return Observable.create( async (observer: Observer<User | false>) => {
            if(cache){
                const cached = await self.getCacheData();
                if(cached){
                  observer.next(cached);
                  self.data.next(cached);
                }else{
                  self.refresh(io).subscribe(data => {
                      observer.next(data);
                      self.data.next(data);
                  });
                }
            }else{
              self.refresh(io).subscribe(data => {
                  observer.next(data);
                  self.data.next(data);
              });
            }
        });
    }

    refresh(io ?: Io): Observable<User | false>{
      const self = this;

      return Observable.create( async (observer: Observer<User | false>) => {
        (io ? io : self.api.new()).silent().get('me').subscribe(async response => {
          await localStorage.setItem(config.me.cache_name, JSON.stringify(response.return));
          observer.next(response.return)
        });
      });
    }

    async update(args: any, io ?: Io): Promise<Response> {
        const self = this;
        const api: Io = io ? io : self.api.new();
        const data = await self.get().pipe(take(1)).toPromise();

        for(let item in args){
            data[item] = args[item];
        }

        const update = await api.put('me', args).pipe(take(1)).toPromise();

        if(update.success){
            await self.get(false).pipe(take(1)).toPromise();
        }

        return update;
    }

    async login(username: string, password: string, io ?: Io): Promise<User | false>{
        const self = this;
        await localStorage.setItem(config.me.cache_name, JSON.stringify({username: username, password: password}));
        return await self.get(false, io).pipe(take(1)).toPromise();
    }

    async logout(){
      return await localStorage.removeItem(config.me.cache_name);
    }

    recoverPassword(field: string, io ?: Io){
      const self = this;
      const api: Io = io ? io : self.api.new().set('success', true);
      return api.get('me/recover-password/' + config.system.recover_password, {field: field});
    }

    updatePassword(form: {password: string, newPassword: string, confirmPassword: string}, io ?: Io){
      const self = this;
      const api: Io = io ? io : self.api.new().set('success', true);
      const post = api.put('me/change-password', form);

      post.subscribe(async data => {
        if(data.success){
          await localStorage.setItem(config.me.cache_name, JSON.stringify(data.return));
        }
      });

      return post;
    }

    async hasPermission(permission: string, field ?: string, key ?: string): Promise<boolean>{
      const self = this;
      const data = await self.get().pipe(take(1)).toPromise();

      if(!data){
        return false;
      }

      for(let item of data.Permissions){
        let _return = false;

        if(item.permission == permission || item.permission == `MASTER`){
          _return = true;
        }

        if(field){
          _return = (item.field == field);
        }

        if(key){
          _return = (item.key == key);
        }

        if(_return){
          return true;
        }
      }

      return false;
    }

    async postoAdmin(postoId: number): Promise<boolean>{
      const self = this;
      const data = await self.get().pipe(take(1)).toPromise();

        console.log('MASTER', await self.hasPermission(`MASTER`));
      if(await self.hasPermission(`MASTER`)){
          return true;
      }

      if(!data){
        return false;
      }

      for(let item of data.PostoColetaUsers || []){
        if(item.postoId == postoId){
            if(item.admin){
                return true;
            }
        }
      }

      return false;
    }
}
