import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import Api from "../../../../helpers/api";
import App from "../../../../helpers/app";
import Me from "../../../../helpers/me";
import {ColetasPage} from "../../coletas.page";
import {EditarLaudoToxicologicoComponent} from "../editar-laudo-toxicologico/editar-laudo-toxicologico.component";
import {EditarLaudoMedicoComponent} from "../editar-laudo-medico/editar-laudo-medico.component";
import * as moment from "moment";
import {EnviarEmailComponent} from "../enviar-email/enviar-email.component";

@Component({
  selector: 'app-opcoes',
  templateUrl: './opcoes.component.html',
  styleUrls: ['./opcoes.component.scss'],
})
export class OpcoesComponent implements OnInit {
  public id = null;
  public item: any = {};
  public page: ColetasPage;
  public master = false;
  public EDIT_COLETA = false;
  public EDIT_LAUDO_TOXICOLOGICO = false;
  public EDIT_LAUDO_MEDICO = false;
  public moment = moment;

  constructor(private modalController: ModalController, private navParams: NavParams, private api: Api, private app: App, private me: Me, private alertController: AlertController) {
    this.me.hasPermission('MASTER').then(bool => this.master = bool);
    this.me.hasPermission('EDIT_COLETA').then(bool => this.EDIT_COLETA = bool);
    this.me.hasPermission('EDIT_LAUDO_MEDICO').then(bool => this.EDIT_LAUDO_MEDICO = bool);
    this.me.hasPermission('EDIT_LAUDO_TOXICOLOGICO').then(bool => this.EDIT_LAUDO_TOXICOLOGICO = bool);

    if(navParams.get('item')){
      this.item = navParams.get('item');
    }
    if(navParams.get('item')){
      this.id = navParams.get('id');
    }

    if(navParams.get('page')){
      this.page = navParams.get('page');
    }

    const self = this;
    if(self.item.id && !self.id){
      self.id = self.item.id;
    }
  }

  ngOnInit() {
    this.get();
  }

  get(){
    const self = this;
    if(self.id){
      self.api.new().get('coletas/' + self.id).subscribe(data => {
        self.item = data.return;
      })
    }
  }

  dismiss(){
    this.modalController.dismiss();
  }

  editar(){
    const self = this;
    self.dismiss();
    self.page.editar(self.id, self.item);
  }

  verLaudo(){
    window.open(this.api.url + 'laudos/' + this.item.Laudo.id + '/pdf/' +  + this.item.Cliente.id + '/' + this.item.ordemServico + '/1');
  }

  async enviarLaudo(){
    const self = this;
    const modal = await this.modalController.create({
      component: EnviarEmailComponent,
      componentProps: {
        'item': self.item,
        'page': self,
        'tipo': 'laudos'
      }
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  verLaudoMedico(){
    window.open(this.api.url + 'laudos-medicos/' + this.item.LaudoMedico.id + '/pdf/' +  this.item.Cliente.id + '/' + this.item.ordemServico + '/1');
  }

  async liberarLaudo(){

    const self = this;
    const modal = await this.modalController.create({
      component: EditarLaudoToxicologicoComponent,
      componentProps: {
        'laudo': self.item.Laudo || {},
        'coleta': self.item,
        'liberar': true
      }
    });
    await modal.present();
    await modal.onWillDismiss();
    this.get();
  }

  async liberarLaudoMedico(){
    const self = this;

    const modal = await this.modalController.create({
      component: EditarLaudoMedicoComponent,
      componentProps: {
        'laudo': self.item.LaudoMedico || {},
        'laudotoxicologico': self.item.Laudo || {},
        'coleta': self.item || {},
        'liberar': true
      }
    });
    await modal.present();
    await modal.onWillDismiss();
    this.ngOnInit();

    // self.item.LaudoMedico.liberado = true;
    // self.item.LaudoMedico.dataLiberacao = new Date();
    // self.api.new().set('success', true).put('laudos-medicos/' + self.item.LaudoMedico.id, self.item.LaudoMedico).subscribe(data => {
    //   if(data.success){
    //     self.dismiss();
    //   }
    // })
  }

  async enviarLaudoMedico(){
    const self = this;
    const modal = await this.modalController.create({
      component: EnviarEmailComponent,
      componentProps: {
        'item': self.item,
        'page': self,
        'tipo': 'laudos-medicos'
      }
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  async enviarFicha(){
    const self = this;
    const modal = await this.modalController.create({
      component: EnviarEmailComponent,
      componentProps: {
        'item': self.item,
        'page': self,
        'tipo': 'coletas'
      }
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  async editarLaudo(){
    const self = this;
    const modal = await this.modalController.create({
      component: EditarLaudoToxicologicoComponent,
      componentProps: {
        'laudo': self.item.Laudo || {},
        'coleta': self.item
      }
    });
    await modal.present();
    await modal.onWillDismiss();
    this.get();
  }

  async editarLaudoMedico(){
    const self = this;
    const modal = await this.modalController.create({
      component: EditarLaudoMedicoComponent,
      componentProps: {
        'laudo': self.item.LaudoMedico || {},
        'laudotoxicologico': self.item.Laudo || {},
        'coleta': self.item || {}
      }
    });
    await modal.present();
    await modal.onWillDismiss();
    this.ngOnInit();
  }

  verFicha(){
    window.open(this.api.url + 'coletas/' + this.item.id + '/pdf');
  }
}
