export default {
  api: {
    production_link: 'https://api.cunhalaboratorio.com.br/',
    development_link: 'http://localhost:3000/'
  },
  me: {
    cache_name: 'user',
    login_field: 'Nome de Usuário'
  },
  system: {
    register: false,
    recover_password: 'email'
  }
};
