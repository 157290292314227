import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import Api from "../../../../helpers/api";
import App from "../../../../helpers/app";

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.page.html',
  styleUrls: ['./editar-usuario.page.scss'],
})
export class EditarUsuarioPage implements OnInit {
  public id = null;
  public item = {
    id: '',
    name: '',
    picture: '',
    username: '',
    password: '',
    email: '',
    admin: false,
    firebaseToken: '',
    prov_password: '',
    status: ''
  };

  constructor(private modalController: ModalController, private navParams: NavParams, private api: Api, private app: App) {
    if(navParams.get('id')){
      this.id = navParams.get('id');
    }
    if(navParams.get('item')){
      this.item = navParams.get('item');
    }
  }

  ngOnInit() {
  }

  dismiss(){
    this.modalController.dismiss();
  }

  action(){
    const self = this;
    self.item.username = self.item.email;
    self.modalController.dismiss({item: self.item});
  }

  async delete() {
    const self = this;
    self.modalController.dismiss({item: false});
  }

  changeListener($event) : void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const self = this;
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();

    myReader.onloadend = (e) => {
      self.item.picture = myReader.result as any;
    }
    myReader.readAsDataURL(file);
  }
}
