import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import Api from "../../../../helpers/api";
import App from "../../../../helpers/app";
import Me from "../../../../helpers/me";

@Component({
  selector: 'app-enviar-email',
  templateUrl: './enviar-email.component.html',
  styleUrls: ['./enviar-email.component.scss'],
})
export class EnviarEmailComponent implements OnInit {

  obj = {
    email: '',
    conteudo: ''
  };

  public item: any = {};
  public tipo = '';

  constructor(private modalController: ModalController, private navParams: NavParams, private api: Api, private app: App, private me: Me, private alertController: AlertController) {
    const self = this;

    if(navParams.get('item')){
      this.item = navParams.get('item');
      self.obj.email = self.item.Cliente.email || '';
    }

    if(navParams.get('tipo')) {
      this.tipo = navParams.get('tipo');
    }

    if(this.tipo == 'laudos'){
      self.obj.conteudo = `Olá ${self.item.Cliente.nome}, <br> Segue seu laudo toxicológico:`
    }

    if(this.tipo == 'laudos-medicos'){
      self.obj.conteudo = `Olá ${self.item.Cliente.nome}, <br> Segue seu laudo médico:`
    }

    if(this.tipo == 'coletas'){
      self.obj.conteudo = `Olá ${self.item.Cliente.nome}, <br> Segue sua ficha de coleta:`
    }

  }

  ngOnInit() {

  }

  enviar(){
    const self = this;

    if(self.tipo == 'laudos') {
      self.api.new().set('success', true).post(self.tipo + '/' + self.item.Laudo.id + '/email/' + self.obj.email, {html: self.obj.conteudo}).subscribe(data => {
        if (data.success) {
          self.dismiss();
        }
      })
    }

    if(self.tipo == 'laudos-medicos') {
      self.api.new().set('success', true).post(self.tipo + '/' + self.item.LaudoMedico.id + '/email/' + self.obj.email, {html: self.obj.conteudo}).subscribe(data => {
        if (data.success) {
          self.dismiss();
        }
      })
    }

    if(self.tipo == 'coletas') {
      self.api.new().set('success', true).post(self.tipo + '/' + self.item.id + '/email/' + self.obj.email, {html: self.obj.conteudo}).subscribe(data => {
        if (data.success) {
          self.dismiss();
        }
      })
    }
  }

  dismiss(){
    this.modalController.dismiss();
  }
}
