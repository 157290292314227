import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import Me  from './me';
import { take } from 'rxjs/operators';

export namespace AuthGuard{

  @Injectable()
  export class Logged implements CanActivate {
    constructor(private me: Me, private router: Router) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      const self = this;
      const user = await self.me.get().pipe(take(1)).toPromise();
      const args = next.data || {};

      if(!user || !('id' in user)){
        self.router.navigate([args.redirect || "/login"]);
        return false;
      }

      if('permission' in args){
        if(!self.me.hasPermission(args.permission)){
          return self.router.navigate(["/coletas"]);
        }
      }

      if('permissions' in args){
        for(const permission of args.permissions){
          if(!self.me.hasPermission(permission)){
            return self.router.navigate(["/coletas"]);
          }
        }
      }

      return true;
    }
  }

  @Injectable()
  export class NotLogged implements CanActivate {
    constructor(private me: Me, private router: Router) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      const self = this;
      const user = await self.me.get().pipe(take(1)).toPromise();
      const args = next.data || {};

      if(user && ('id' in user)){
        self.router.navigate([args.redirect || "/usuarios"]);
        return false;
      }

      return true;
    }
  }
}
