import { Component, OnInit } from '@angular/core';
import Api from "../../../../helpers/api";
import {ModalController, NavParams} from "@ionic/angular";
import App from "../../../../helpers/app";
import * as moment from "moment";
import Me from "../../../../helpers/me";

@Component({
  selector: 'app-editar-laudo-toxicologico',
  templateUrl: './editar-laudo-toxicologico.component.html',
  styleUrls: ['./editar-laudo-toxicologico.component.scss'],
})
export class EditarLaudoToxicologicoComponent implements OnInit {
  laudo: any = {};
  coleta: any = {};
  moment = moment;
  master = false;


// {name: 'Número da Nota Fiscal', field: 'NFnumero', required: false},
// {name: 'URL da Nota Fiscal', field: 'NFurl', required: false},
// {name: 'Data da Nota Fiscal', field: 'NFData', placeholder: '00/00/0000 00:00', required: false, type: 'dateTime'},
// {name: 'Observações da Nota Fiscal', field: 'NFobs', placeholder: 'Opcional'},

  anfetaminas = [
    {coluna: "afetamina", label: "(0,2ng/mg)/(0,2ng/mg)", nome: 'anfetamina'},
    {coluna: "metafetamina", label: "(0,2ng/mg)/(0,2ng/mg)", nome: 'Metanfetamina'},
    {coluna: "anfepramona", label: "(0,2ng/mg)/(0,2ng/mg)"},
    {coluna: "ecstasy", label: "(0,2ng/mg)/(0,2ng/mg)", nome: 'MDMA (Ecstasy)'},
    {coluna: "mda", label: "(0,2ng/mg)/(0,2ng/mg)"},
    {coluna: "femproporex", label: "(0,2ng/mg)/(0,2ng/mg)"},
    {coluna: "mazindol", label: "(0,5ng/mg) / (0,5ng/mg)"},
  ];
  cocaina = [
    {coluna: "cocaina", label: "(0,5ng/mg) / (0,5ng/mg)", nome: 'Cocaína'},
    {coluna: "crack", label: "(0,5ng/mg) / (0,5ng/mg)", nome: 'AEME Crack'},
    {coluna: "benzoilecgonia", label: "(0,5ng/mg) / (0,5ng/mg)"},
    {coluna: "cocaetileno", label: "(0,5ng/mg) / (0,5ng/mg)", nome: 'Cocaetileno (Cocaína + Álccol)'},
    {coluna: "norcaina", label: "(0,5ng/mg) / (0,5ng/mg)"},
  ];
  opiaceos = [
    {coluna: "heroina", label: "(0,2ng/mg)/(0,2ng/mg)", nome: 'Acetilmorfina (Heroína)'},
    {coluna: "codeina", label: "(0,2ng/mg)/(0,2ng/mg)"},
    {coluna: "morfina", label: "(0,2ng/mg)/(0,2ng/mg)"},
  ];
  maconha = [
    {coluna: "thc", label: "(0,05ng/mg) / (0,05ng/mg)"},
    {coluna: "carboxy", label: "(0,0002ng/mg) / (0,0002ng/mg)", nome: 'Carboxy THC'},
  ];

  liberar = false;

  constructor(private api: Api, private modalController: ModalController, private navParams: NavParams, private app: App, private me: Me) {
    this.laudo = navParams.get('laudo') || {};
    this.coleta = navParams.get('coleta') || {};
    this.liberar = navParams.get('liberar') || false;
    this.laudo.coletaId = this.coleta.id;
    this.me.hasPermission(`MASTER`).then(bool => this.master = bool);
  }

  ngOnInit() {
  }

  save(){
    const self = this;
    if(self.laudo.id){

      if(self.liberar){
        self.laudo.liberado = true;
        self.laudo.dataLiberacao = new Date();
      }


      self.api.new().set('success', false).put('laudos/' + self.laudo.id, self.laudo).subscribe(data => {
        if(data.success){
          self.app.alert(`Sucesso`, self.liberar ? 'Laudo liberado com sucesso!': 'Laudo editado com sucesso!')
          self.dismiss();
        }
      })
    }else{
      self.api.new().set('success', true).post('laudos/', self.laudo).subscribe(data => {
        if(data.success){
          self.dismiss();
        }
      })
    }
  }

  async delete(){
    const self = this;
    if(self.laudo.id){
      if(await self.app.confirm()){
        self.api.new().set('success', true).delete('laudos/' + self.laudo.id).subscribe(data => {
          console.log(`data`, data);
        })
      }
    }
  }

  dismiss(){
    this.modalController.dismiss();
  }

  selected(ev, index, index2 = 'id'){
    this.laudo[index] = ev[index2];
  }

  verLaudo(){
    window.open(this.api.url + 'laudos/' + this.laudo.id + '/pdf/' + this.coleta.Cliente.id + '/' + this.coleta.ordemServico + '/1');
  }
}
