import {Component, OnInit} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import Api from "../../helpers/api";
import {ListaPage} from "./modals/lista/lista.page";
import {EditarPage} from "./modals/editar/editar.page";
import me from "../../helpers/me";

@Component({
    selector: 'app-postos',
    templateUrl: './postos.page.html',
    styleUrls: ['./postos.page.scss'],
})
export class PostosPage implements OnInit {
    items = [];
    mobile = false;

    filters = {
        codigo: '',
        nome: '',
        cnpj: '',
        telefones: '',
        email: '',
        cidade: '',
        responsavel: ''
    };

    master = false;

    constructor(private api:Api, private modalController: ModalController, protected me: me) {
        this.mobile = window.innerWidth < 1024;
        this.me.hasPermission(`MASTER`).then(bool =>  this.master = bool);
    }

    ngOnInit() {
        this.get();
    }


    offset = 0;

    async get(){
        const self = this;
        const filters = [];

        for(let item in self.filters){
            if(self.filters[item]){
                filters.push({
                    [item]: {
                        'Op.or': [
                            {'Op.like': `%${self.filters[item].split(" ").join("%")}%`},
                            {'Op.like': `${self.filters[item].split(" ").join("%")}`}
                        ]
                    }
                });
            }
        }
        const api = this.api.new();
        api.post('postos/search/0/20', Object.values(filters). length == 0 ? {} : {where: {'Op.and': filters}}).subscribe(data => {
            this.items = (data.success) ? data.return.rows : [];
            this.offset = 0;
        });
    }

    async getMore(event){
        this.offset += 20;

        const self = this;
        const filters = {};

        for(let item in self.filters){
            if(self.filters[item]){
                filters[item] = self.filters[item]
            }
        }

        const api = this.api.new();
        api.post('postos/search/' + this.offset + '/20', {'Op.and': filters}).subscribe(
            data => {
                if(data.success){
                    self.items = self.items.concat(data.return.rows);
                    if(data.return.rows < 20){
                        event.target.disabled = true;
                    }
                }
                event.target.complete()
            },
            error => {
                event.target.complete()
            }
        );
    }

    async search() {
        const modal = await this.modalController.create({
            component: ListaPage,
            componentProps: {
                'filters': this.filters
            }
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();

        if(data && data.filters){
            this.filters = data.filters;
            this.get();
        }
    }

    async editar(id: number | null, item ?: any) {
        const modal = await this.modalController.create({
            component: EditarPage,
            componentProps: {'id': id, 'item': item, 'readonly': !await this.me.postoAdmin(id)}
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        this.get();
    }
}
