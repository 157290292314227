import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import * as moment from "moment";

@Component({
  selector: 'app-procurar-generico',
  templateUrl: './procurar-generico.component.html',
  styleUrls: ['./procurar-generico.component.scss'],
})
export class ProcurarGenericoComponent implements OnInit {
  public titulo = 'Procurar';
  public fields = [];
  public filters = {};

  moment = moment;

  constructor(private modalController: ModalController, private navParams: NavParams) {
    const filters =  navParams.get('params');


    this.filters = filters;
    this.titulo = navParams.get('titulo');
    this.fields = navParams.get('fields');
  }

  ngOnInit() {
  }

  dismiss(){
    this.modalController.dismiss();
  }

  search(){
    this.modalController.dismiss({
      filters: this.filters
    });
  }

  selecionado(item, field){
    if(item && 'id' in item){
      this.filters[field] = item.id;
    }
  }
}
