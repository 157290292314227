import { Component, OnInit } from '@angular/core';
import Api from "../../../../helpers/api";
import {ModalController, NavParams} from "@ionic/angular";
import App from "../../../../helpers/app";
import Me from "../../../../helpers/me";

@Component({
  selector: 'app-editar-laudo-medico',
  templateUrl: './editar-laudo-medico.component.html',
  styleUrls: ['./editar-laudo-medico.component.scss'],
})
export class EditarLaudoMedicoComponent implements OnInit {
  laudo: any = {
    coletaId: 0,
    laudoId: 0,
    confidencial1: null,
    confidencial2: null,
  };
  coleta: any = {};
  laudotoxicologico: any = {};
  liberar = false;
  master = false;

  constructor(private api: Api, private modalController: ModalController, private navParams: NavParams, private app: App, private me: Me) {
    if(navParams.get('laudotoxicologico')){
      this.laudotoxicologico = navParams.get('laudotoxicologico');
    }
    if(navParams.get('laudo')){
      this.laudo = navParams.get('laudo');
      console.log(`this.laudo`,this.laudo);
    }
    if(navParams.get('coleta')){
      this.coleta = navParams.get('coleta');
    }

    this.liberar = navParams.get('liberar') || false;

    this.laudo.coletaId = this.coleta.id;
    this.laudo.laudoId = this.laudotoxicologico.id;
    console.log(`this.laudo`,this.laudo);

    this.me.hasPermission(`MASTER`).then(bool => this.master = bool);
  }

  ngOnInit() {
  }

  save(){
    const self = this;
    if(self.laudo.id){

      if(self.liberar){
        self.laudo.liberado = true;
        self.laudo.dataLiberacao = new Date();
      }

      self.api.new().set('success', false).put('laudos-medicos/' + self.laudo.id, self.laudo).subscribe(data => {
        if(data.success){
          self.app.alert(`Sucesso`, self.liberar ? 'Laudo liberado com sucesso!': 'Laudo editado com sucesso!')
          self.dismiss();
        }
      })
    }else{
      self.api.new().set('success', true).post('laudos-medicos', self.laudo).subscribe(data => {
        if(data.success){
          self.dismiss();
        }
      })
    }
  }

  async delete(){
    const self = this;
    if(self.laudo.id){
      if(await self.app.confirm()){
        self.api.new().set('success', true).delete('laudos-medicos/' + self.laudo.id).subscribe(data => {
          console.log(`data`, data);
        })
      }
    }
  }

  dismiss(){
    this.modalController.dismiss();
  }

  verLaudo(){
    window.open(this.api.url + 'laudos-medicos/' + this.laudo.id + '/pdf/' + this.coleta.Cliente.id + '/' + this.coleta.ordemServico + '/1');
  }

}
