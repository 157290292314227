import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PostosPage } from './postos.page';

const routes: Routes = [
  {
    path: '',
    component: PostosPage
  },
  {
    path: 'editar',
    loadChildren: () => import('./modals/editar/editar.module').then( m => m.EditarPageModule)
  },
  {
    path: 'lista',
    loadChildren: () => import('./modals/lista/lista.module').then( m => m.ListaPageModule)
  },
  {
    path: 'editar-usuario',
    loadChildren: () => import('./modals/editar-usuario/editar-usuario.module').then( m => m.EditarUsuarioPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PostosPageRoutingModule {}
