import { Component, OnInit } from '@angular/core';
import Api from "../../../../helpers/api";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-revisor',
  templateUrl: './revisor.component.html',
  styleUrls: ['./revisor.component.scss'],
})
export class RevisorComponent implements OnInit {
  informacoes = '';

  constructor(private api: Api, private modalController: ModalController) { }

  ngOnInit() {
    this.api.new().get('variable/revisor').subscribe(data => {
      this.informacoes = (data.return || {}).value || '';
    })
  }

  save(){
    const self = this;
    self.api.new().set('success', true).post('variable/revisor', {variable: self.informacoes.replace(/<[^>]*>?/gm, '')}).subscribe(data => {
      self.informacoes = (data.return || {}).value || '';
      if(data.success){
        self.dismiss();
      }
    })
  }

  dismiss(){
    this.modalController.dismiss();
  }
}
