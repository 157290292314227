import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validafoto'
})
export class ValidafotoPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    if(typeof value !== "string" || !value){
      return '/assets/images/no-pic.jpg';
    }
    return value && value.indexOf('http') > -1 ? value : '/assets/images/no-pic.jpg';
  }

}
