import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UcwordsPipe} from "../../pipes/ucwords/ucwords.pipe";
import {NgxMaskModule} from "ngx-mask";
import {NoItemComponent} from "../../components/no-item/no-item.component";
import {IonicModule, NavParams} from "@ionic/angular";
import { BrMaskerModule } from 'br-mask';
import {ValidafotoPipe} from "../../pipes/validafoto/validafoto.pipe";
import Me from "../../helpers/me";
import {ClientesSelectComponent} from "../../components/clientes-select/clientes-select.component";
import {PostosDeColetaSelectComponent} from "../../components/postos-de-coleta-select/postos-de-coleta-select.component";
import {FormsModule} from "@angular/forms";
import {LotesSelectComponent} from "../../components/lotes-select/lotes-select.component";
import {ColetasSelectComponent} from "../../components/coletas-select/coletas-select.component";

@NgModule({
    entryComponents: [PostosDeColetaSelectComponent],
    declarations: [UcwordsPipe, NoItemComponent, ValidafotoPipe, PostosDeColetaSelectComponent, LotesSelectComponent, ColetasSelectComponent],
  imports: [CommonModule, NgxMaskModule, IonicModule, BrMaskerModule, FormsModule],
    providers: [Me, NavParams],
    exports: [UcwordsPipe, NgxMaskModule, NoItemComponent, NoItemComponent, BrMaskerModule, ValidafotoPipe, PostosDeColetaSelectComponent, FormsModule, LotesSelectComponent, ColetasSelectComponent]
})
export class CommonModuleModule { }
