import { Component, OnInit } from '@angular/core';
import Api from "../../../../helpers/api";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-informacoes',
  templateUrl: './informacoes.component.html',
  styleUrls: ['./informacoes.component.scss'],
})
export class InformacoesComponent implements OnInit {
  informacoes = '';

  constructor(private api: Api, private modalController: ModalController) { }

  ngOnInit() {
    this.api.new().get('variable/infolaudo').subscribe(data => {
      this.informacoes = (data.return || {}).value || '';
    })
  }

  save(){
    const self = this;
    self.api.new().set('success', true).post('variable/infolaudo', {variable: this.informacoes}).subscribe(data => {
      self.informacoes = (data.return || {}).value || '';
      if(data.success){
        self.dismiss();
      }
    })
  }

  dismiss(){
    this.modalController.dismiss();
  }
}
