import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Api from "../../helpers/api";
import Me from "../../helpers/me";

@Component({
  selector: 'app-coletas-select',
  templateUrl: './coletas-select.component.html',
  styleUrls: ['./coletas-select.component.scss'],
})
export class ColetasSelectComponent implements OnInit {
  filters = {ordemServico: ''};
  items = [];
  index = -1;

  @Output() selected = new EventEmitter();
  @Input() value = null;
  @Input() disabled = false;

  constructor(private api:Api, private me: Me) {
  }

  emit(){
    this.selected.emit(this.items[this.index]);
  }

  ngOnInit() {

  }

  ngOnChanges() {
    const self = this;
    self.items = [];
    self.get().then(data => {
      self.selected.emit(self.items[self.index]);
    })
  }

  async get(change=false){
    const self = this;
    const filters = [];

    for(let item in self.filters){
      if(self.filters[item]){
        filters.push({
          [item]: (typeof self.filters[item] !== 'string') ?  self.filters[item] : {
            'Op.or': [
              {'Op.like': `%${self.filters[item].split(" ").join("%")}%`},
              {'Op.like': `${self.filters[item].split(" ").join("%")}`}
            ]
          }
        });
      }
    }
    const api = this.api.new();
    api.post('coletas/search/0/200', Object.values(filters). length == 0 ? {} : {where: {'Op.and': filters}}).subscribe(data => {
      this.items = (data.success) ? data.return.rows : [];

      if(self.value){
        self.items.push(self.value);
        self.index = self.items.length-1;
        self.selected.emit(self.items[self.index]);
      }

      if(change){
        self.index = self.items.length-1;
        self.selected.emit(self.items[self.index]);
      }
    });
  }
}
