import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import Api from "../../../../helpers/api";
import App from "../../../../helpers/app";
import {ListaPage} from "../lista/lista.page";
import {EditarUsuarioPage} from "../editar-usuario/editar-usuario.page";

@Component({
  selector: 'postos-editar',
  templateUrl: './editar.page.html',
  styleUrls: ['./editar.page.scss'],
})
export class EditarPage implements OnInit {
  public id = null;
  public item: any = {
    foto: '',
    codigo: '',
    users: [],
    PostoColetaUsers: [],
    cep: ''
  };

  segment = 'posto';
  edit= true;
  readonly = false;

  constructor(private modalController: ModalController, private navParams: NavParams, private api: Api, private app: App) {
    const self = this;

    if(navParams.get('readonly')){
      this.readonly = navParams.get('readonly');
    }
    if(navParams.get('id')){
      this.id = navParams.get('id');
    }
    if(navParams.get('item')){
      this.item = navParams.get('item');
    }
    if(navParams.get('edit')){
      this.edit = navParams.get('edit');
    }

    if(!self.item.users){
      self.item.users = [];
    }

    for(let item of self.item.PostoColetaUsers || []){
      self.item.users.push(item.User);
    }
  }

  ngOnInit() {
  }

  dismiss(){
    this.modalController.dismiss();
  }

  action(){
    const self = this;
    const api = self.api.new().set('success', true);

    if(self.id){
      api.put('postos/' + self.id, self.item).subscribe(data => {
        if(data.success){
          this.modalController.dismiss({posto: data.return});
        }
      })
    }else{
      api.post('postos/', self.item).subscribe(data => {
        if(data.success){
          this.modalController.dismiss({posto: data.return});
        }
      })
    }

  }

  obterCep(cep: string){
    const self = this;

  /*
      bairro: "Niterói"
      cep: "92120-210"
      complemento: ""
      gia: ""
      ibge: "4304606"
      localidade: "Canoas"
      logradouro: "Rua Marechal Rondon"
      uf: "RS"
      unidade: ""
  */
    this.api.get(`https://viacep.com.br/ws/${cep.replace(/[^0-9]/g,'')}/json/`).then((data: any) => {
      console.log(`data`, data);
      if(data.cep){
        self.item.endereco = data.logradouro;
        self.item.bairro = data.bairro;
        self.item.cidade = data.localidade;
        self.item.estado = data.uf;
      }
    })
  }

  async delete() {
    const self = this;
    if(await self.app.confirm()){
      const api = self.api.new().set('success', true);
      api.delete('postos/' + self.id).subscribe(data => {
        if(data.success){
          this.modalController.dismiss();
        }
      })
    }
  }

  async adicionarUsuario(){
    const modal = await this.modalController.create({
      component: EditarUsuarioPage
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();

    if(data && data.item){
      this.item.users.push(data.item);
    }
  }

  async editarUsuario(item, index){
    const self = this;
    const modal = await this.modalController.create({
      component: EditarUsuarioPage,
      componentProps: {
        item: item,
        id: 1
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();

    if(data){
      console.log(`data.item`, data.item, index);
      if(data.item === false){
        self.item.users.splice(index,1);
      }else{
        self.item.users[index] = data.item;
      }
    }
  }

  changeListener($event) : void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const self = this;
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();

    myReader.onloadend = (e) => {
      self.item.foto = myReader.result as any;
    }
    myReader.readAsDataURL(file);
  }

  public handleAddressChange(address) {
    // Do some stuff
    console.log(`address`, address);
  }
}
