import { Component, OnInit } from '@angular/core';
import {Base} from "../base.crud";

@Component({
  selector: 'app-doadores',
  templateUrl: './doadores.page.html',
  styleUrls: ['./doadores.page.scss'],
})
export class DoadoresPage extends Base {
  route = 'clientes';

  ngOnInit() {
    super.ngOnInit();
    this.modals.procurar.fields = [
      {name: 'Posto de Coleta', field: 'postoId', type: 'posto'},
      {name: 'Nome', field: 'nome'},
      {name: 'Endereço', field: 'endereco'},
      {name: 'Número', field: 'numero'},
      {name: 'Complemento', field: 'complemento'},
      {name: 'Bairro', field: 'bairro'},
      {name: 'Cidade', field: 'cidade'},
      {name: 'Estado', field: 'estado'},
      {name: 'Cep', field: 'cep', placeholder: '00.000-000'},
      {name: 'RG', field: 'rg'},
      {name: 'CPF', field: 'cpf'},
      {name: 'Sexo', field: 'sexo', type: 'select', options: [{name: 'Masculino', value: 'Masculino'},{name: 'Feminino', value: 'Feminino'}]},
      {name: 'Telefone', field: 'telefone'},
      {name: 'E-mail', field: 'email'},
      {name: 'Status', field: 'status', type: 'select', options: [{name: 'Ativo', value: 1},{name: 'Inativo', value: 0}]},
    ];
    this.modals.editar.foto = {
      field: 'foto'
    };
    this.modals.editar.fields = [
      {name: 'Posto de Coleta', value: 'PostoColeta', field: 'postoId', type: 'posto', required: true},
      {name: 'Nome', field: 'nome', required: true},
      {name: 'RG', field: 'rg'},
      {name: 'CPF', field: 'cpf', required: true, mask: {mask:'000.000.000-00', len:14, type: 'num'}, placeholder: '000.000.000-00'},
      {name: 'Data de Nascimento', field: 'dataNascimento', placeholder: '00/00/0000', required: true, type: 'date'},
      {name: 'Sexo', field: 'sexo', type: 'select', options: [{name: 'Masculino', value: 1},{name: 'Feminino', value: 0}], required: true},
      {name: 'Telefone', field: 'telefone', mask: {mask:'(00) 00000-0000', len:19, type: 'num'}, placeholder: '+55 (00) 00000-0000'},
      {name: 'E-mail', field: 'email', required: false},
      {name: 'Cep', field: 'cep', mask: {mask:'00.000-000', len:10, type: 'num'}, placeholder: '00.000-000', type: 'cep'},
      {name: 'Endereço', field: 'endereco'},
      {name: 'Número', field: 'numero'},
      {name: 'Complemento', field: 'complemento'},
      {name: 'Bairro', field: 'bairro'},
      {name: 'Cidade', field: 'cidade'},
      {name: 'Estado', field: 'estado'}
    ]
  }

  async editar(id: number | null, item?: any) {
    const self = this;
    return await super.editar(id, item, await self.me.postoAdmin(item.postoId) || !id);
  }
}
