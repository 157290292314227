import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import App from './helpers/app';
import Api from './helpers/api';
import Me from './helpers/me';
import {AuthGuard} from './helpers/auth-guard';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgxMaskModule} from "ngx-mask";
import {ListaPage} from "./pages/postos/modals/lista/lista.page";
import {EditarPage} from "./pages/postos/modals/editar/editar.page";
import {EditarUsuarioPage} from "./pages/postos/modals/editar-usuario/editar-usuario.page";
import {CommonModuleModule} from "./modules/common-module/common-module.module";
import {EditarUsuarioPage as EditarUsuarioUsPage} from "./pages/usuarios/modals/editar-usuario/editar-usuario.page";
import {EditarUsuarioPageModule} from "./pages/usuarios/modals/editar-usuario/editar-usuario.module";
import {PostosDeColetaSelectComponent} from "./components/postos-de-coleta-select/postos-de-coleta-select.component";
import {ListaUsuariosPage} from "./pages/usuarios/modals/lista-usuarios/lista-usuarios.page";
import {AdicionarGenericoComponent} from "./components/adicionar-generico/adicionar-generico.component";
import {ProcurarGenericoComponent} from "./components/procurar-generico/procurar-generico.component";
import {PostosPageModule} from "./pages/postos/postos.module";
import {ClientesSelectComponent} from "./components/clientes-select/clientes-select.component";
import {ColetoresSelectComponent} from "./components/coletores-select/coletores-select.component";
import {TestemunhasSelectComponent} from "./components/testemunhas-select/testemunhas-select.component";
import {OpcoesComponent} from "./pages/coletas/components/opcoes/opcoes.component";
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import {InformacoesComponent} from "./pages/laudos/components/informacoes/informacoes.component";
import { CKEditorModule } from 'ckeditor4-angular';
import {RevisorComponent} from "./pages/coletas/components/revisor/revisor.component";
import {EditarLaudoToxicologicoComponent} from "./pages/coletas/components/editar-laudo-toxicologico/editar-laudo-toxicologico.component";
import {DoadoresPageModule} from "./pages/doadores/doadores.module";
import {EditarLaudoMedicoComponent} from "./pages/coletas/components/editar-laudo-medico/editar-laudo-medico.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from "@angular/common";
import { ChartsModule } from 'ng2-charts';
import {EditProfileComponent} from "./modals/edit-profile/edit-profile.component";
import {EnviarEmailComponent} from "./pages/coletas/components/enviar-email/enviar-email.component";
import {EditarLotesComponent} from "./components/editar-lotes/editar-lotes.component";

registerLocaleData(localePt);

@NgModule({
    declarations: [AppComponent, ListaPage, EditarPage, EditarUsuarioPage, EditarUsuarioUsPage, ListaUsuariosPage, AdicionarGenericoComponent, ProcurarGenericoComponent, ClientesSelectComponent, ColetoresSelectComponent, TestemunhasSelectComponent, OpcoesComponent, InformacoesComponent, RevisorComponent, EditarLaudoToxicologicoComponent, EditarLaudoMedicoComponent, EditProfileComponent, EnviarEmailComponent, EditarLotesComponent],
    entryComponents: [ListaPage, EditarPage, EditarUsuarioPage, EditarUsuarioUsPage , ListaUsuariosPage, AdicionarGenericoComponent, ProcurarGenericoComponent, ClientesSelectComponent, OpcoesComponent, InformacoesComponent, RevisorComponent, EditarLaudoToxicologicoComponent, EditarLaudoMedicoComponent, EditProfileComponent, EnviarEmailComponent, EditarLotesComponent],
    imports: [
        GooglePlaceModule,
        BrowserModule,
        IonicModule.forRoot({}),
        AppRoutingModule,
        HttpClientModule,
        NgxMaskModule.forRoot(),
        EditarUsuarioPageModule,
        CommonModuleModule,
        PostosPageModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        CKEditorModule,
        DoadoresPageModule,
        ChartsModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LOCALE_ID, useValue: 'pt-BR'},
        App,
        Api,
        Me,
        AuthGuard.Logged,
        AuthGuard.NotLogged
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
