import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import Api from "../../helpers/api";
import App from "../../helpers/app";
import Me from "../../helpers/me";
import * as moment from "moment";
import {OpcoesComponent} from "../../pages/coletas/components/opcoes/opcoes.component";
import {ColetasPage} from "../../pages/coletas/coletas.page";
import {Params} from "@angular/router";
import {AdicionarGenericoComponent} from "../adicionar-generico/adicionar-generico.component";

@Component({
  selector: 'app-editar-lotes',
  templateUrl: './editar-lotes.component.html',
  styleUrls: ['./editar-lotes.component.scss'],
})
export class EditarLotesComponent extends ColetasPage {
  public id = null;
  public item: any = {};

  public filters = {};

  public route = '';
  public titulo = '';
  public fields = [];
  public foto = null;
  public readonly = false;

  public moment = moment;

  ngOnInit() {
    const self = this;
    super.ngOnInit();

    // this.activedRoute.params.subscribe((params: Params) => {
    //   self.id = params['id'];
    //   if(self.id){
    //     self.opcoes({id: self.id});
    //   }
    // });

    this.modals.procurar.fields = [
      {name: 'FCC', field: 'ordemServico'},
      {name: 'Data de Coleta', field: 'dataColeta', type: 'date'},
      {name: 'Posto de Coleta', field: 'postoId', type: 'posto', required: true},
      {name: 'Finalidade', field: 'finalidade', type: 'finalidade', required: true},
      {name: 'Número do Registro', field: 'numeroRegistro', required: true},
      {name: 'Cliente', field: 'clienteId', type: 'cliente', required: true},
      {name: 'Nº Código de Barras', field: 'ordemServico', required: true},
      {
        name: 'Amostra Coletada',
        type: 'radio',
        subitems: ['Cabelos', 'Pelos'],
        field: 'amostraColetada',
        required: true
      },
      {
        name: 'Local da Amostra Coletada',
        type: 'radio',
        subitems: ['Cabeça'],
        field: 'amostraLocal',
        required: true
      },
      {
        name: 'Cor da Amostra Coletada', type: 'radio', subitems: [
          'Castanho claro', 'Castanho escuro', 'Preto', 'Loiro', 'Ruivo', 'Branco', 'Grisalho'
        ], field: 'amostraCor', required: true
      },
      {name: 'Tamanho Amostra', field: 'amostraTamanhoCabelo', placeholder: 'cm (Mín. 3,0cm)', required: true},
      {
        name: 'Tratamento químico capilar?',
        type: 'radio',
        subitems: ['Não', 'Sim'],
        field: 'tratamentoQuimico',
        required: true
      },
      {
        name: 'Utilizou algum medicamento interferente nos últimos 6 meses?',
        type: 'radio',
        subitems: ['Não', 'Sim'],
        field: 'medicamentoInterferente',
        required: true
      },

      {
        name: 'Relato de substâncias',
        field: 'relatoSubstancias',
        placeholder: 'Relato de substâncias ilícitas, periódo e frequência de uso.'
      },

      {name: 'Coletor', field: 'coletorId', type: 'coletor', required: true},
      {name: 'Testemunha', field: 'testemunhaId', type: 'testemunha', required: true},
    ];

    this.modals.editar.fields = [
      // { name: 'Data de Coleta', field: 'dataColeta', type: 'dataColeta', required: true },
      {name: 'Lote', value: 'Lote', field: 'loteId', type: 'lote', required: false, canAdd: true},
      {name: 'Posto de Coleta', value: 'PostoColeta', field: 'postoId', type: 'posto', required: true},
      {name: 'Cliente', value: 'Cliente', field: 'clienteId', type: 'cliente', required: true, canAdd: true},
      {name: 'Finalidade', field: 'finalidade', type: 'finalidade', required: true},
      {name: 'Nº Código de Barras', field: 'ordemServico', required: true},
      {
        name: 'Amostra Coletada',
        type: 'radio',
        subitems: ['Cabelos', 'Pelos'],
        field: 'amostraColetada',
        required: true
      },
      {
        name: 'Local da Amostra Coletada',
        type: 'radio',
        subitems: ['Cabeça', 'Axila', 'Tórax', 'Braço', 'Perna', 'Costas', 'Pubianos'],
        field: 'amostraLocal',
        required: true
      },
      {
        name: 'Cor da Amostra Coletada', type: 'radio', subitems: [
          'Castanho claro', 'Castanho escuro', 'Preto', 'Loiro', 'Ruivo', 'Branco', 'Grisalho'
        ], field: 'amostraCor', required: true
      },
      {name: 'Tamanho Amostra', field: 'amostraTamanhoCabelo', placeholder: 'cm (Mín. 3,0cm)', required: true},

      {
        name: 'Grupo de drogas a serem analisadas',
        type: 'ion-text',
        text: 'Anfetamina; metanfetamina; anfepramona; MDMA (ecstasy); MDA; femproporex; mazindol; cocaína; AEME (crack); benzoilecgonina; cocaetileno (cocaína + álcool); norcocaína; 6-acetilmorfina; codeína; morfina; THC; carboxy THC (THCCOOH)'
      },


      {
        name: 'Tratamento químico capilar?',
        type: 'radio',
        subitems: ['Não', 'Sim'],
        field: 'tratamentoQuimico',
        required: true
      },
      {
        name: 'Utilizou algum medicamento interferente nos últimos 6 meses?',
        type: 'radio',
        subitems: ['Não', 'Sim'],
        field: 'medicamentoInterferente',
        required: true
      },

      {
        name: 'Relato de substâncias',
        field: 'relatoSubstancias',
        placeholder: 'Relato de substâncias ilícitas, periódo e frequência de uso.'
      },

      {name: 'Coletor', value: 'Coletor', field: 'coletorId', type: 'coletor', required: true},
      {name: 'Testemunha', value: 'Testemunha', field: 'testemunhaId', type: 'testemunha', required: true},
      {name: 'Valor Nota Fiscal', field: 'valorNF', required: false},
    ]

    if(this.navParams.get('route')){
      this.route = this.navParams.get('route');
    }
    if(this.navParams.get('id')){
      this.id = this.navParams.get('id');
    }
    if(this.navParams.get('readonly')){
      this.readonly = this.navParams.get('readonly');
    }

    if(this.navParams.get('item')){
      this.item = this.navParams.get('item');
    }

    if(this.navParams.get('foto')){
      this.foto = this.navParams.get('foto');
    }

    this.filters = this.navParams.get('params');
    this.titulo = this.navParams.get('titulo');
    this.fields = this.navParams.get('fields');

    console.log(`item`, this.item);
  }

  dismiss(){
    this.modalController.dismiss();
  }

  action(){
    const self = this;
    const api = self.api.new().set('success', true);

    if(self.id){
      api.put(self.route + '/' + self.id, self.item).subscribe(data => {
        if(data.success){
          self.modalController.dismiss(data.return);
        }
      })
    }else{
      api.post(self.route + '/', self.item).subscribe(data => {
        if(data.success){
          self.modalController.dismiss(data.return);
        }
      })
    }

  }

  async delete() {
    const self = this;

    if(await self.app.confirm(`Apagar`, `Você tem certeza de que deseja apagar?`)){
      const api = self.api.new().set('success', true);
      api.delete(self.route + '/' + self.id).subscribe(data => {
        if(data.success){
          self.modalController.dismiss();
        }
      })
    }
  }

  changeListener($event, field) : void {
    if(field){
      this.readThis($event.target, field);
    }
  }

  readThis(inputValue: any, field: string): void {
    const self = this;
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();

    myReader.onloadend = (e) => {
      self.item[field] = myReader.result as any;
    }
    myReader.readAsDataURL(file);
  }

  postoSelecionado(posto, field){
    if(posto && 'id' in posto){
      this.item[field] = posto.id;
    }
  }
  selecionado(item, field){
    if(item){
      this.item[field] = item.id;
    }
  }

  addColeta(f, value){
    this.item.Coletas.push(Object.assign({}, value));
  }

  excel(){
    this.api.new().goToLink(`lotes/${this.id}/excel`);
  }

  obterCep(cep: string, item){
    const self = this;

    /*
        bairro: "Niterói"
        cep: "92120-210"
        complemento: ""
        gia: ""
        ibge: "4304606"
        localidade: "Canoas"
        logradouro: "Rua Marechal Rondon"
        uf: "RS"
        unidade: ""
    */

    if(cep){
      self.api.get(`https://viacep.com.br/ws/${(cep || '').replace(/[^0-9]/g,'')}/json/`).then((data: any) => {
        console.log(`data`, data);
        if(data.cep){
          item.endereco = data.logradouro;
          item.bairro = data.bairro;
          item.cidade = data.localidade;
          item.estado = data.uf;
        }
      })
    }
  }

  can(){
    const self = this;
    for(let f of self.fields){
      if(f.required && (self.item[f.field] == null || self.item[f.field] === '')){
        return false;
      }
    }

    return true;
  }

  push(array, object){
    array.push(Object.assign({}, object));
  }

  Object = Object;
  CqiInicial:any = {};
  CqiFinais:any = {};
  Equipamento:any = {};
  Insumos:any = {};
  segment = 'Lote';


  async opcoes(item: any, lote ?: any) {
    const self = this;

    const coleta = Object.assign({}, item);

    if(lote){
      coleta.Lote = lote;
      coleta.loteId = lote.id;
    }

    console.log(`coleta`, coleta);

    const modal = await this.modalController.create({
      component: OpcoesComponent,
      componentProps: {
        'item': coleta,
        'page': self
      }
    });
    await modal.present();
    await modal.onWillDismiss();

    this.ngOnInit();
  }

  async editar(id: number | null, item?: any, canEdit: boolean = true) {
    const self = this;
    return new Promise(async resolve => {
      const modal = await this.modalController.create({
        component: AdicionarGenericoComponent,
        componentProps: {
          'id': id,
          'item': Object.assign({},item),
          'route': 'coletas',
          'foto': self.modals.editar.foto,
          'fields': self.modals.editar.fields,
          'titulo': self.modals.editar.titulo,
          'readonly': !canEdit
        }
      });
      await modal.present();
      await modal.onWillDismiss();

      self.api.new().get('lotes/' + item.Lote.id).subscribe(data => {
        if(data.return){
          self.item = data.return;
        }

        resolve(true)
      })
    })
  }
}
