import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './helpers/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'coletas',
    pathMatch: 'full'
  },
  {
    path: 'postos',
    loadChildren: () => import('./pages/postos/postos.module').then( m => m.PostosPageModule),
    canActivate: [AuthGuard.Logged]
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./pages/usuarios/usuarios.module').then( m => m.UsuariosPageModule),
    canActivate: [AuthGuard.Logged]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [AuthGuard.NotLogged]
  },
  {
    path: 'doadores',
    loadChildren: () => import('./pages/doadores/doadores.module').then( m => m.DoadoresPageModule),
    canActivate: [AuthGuard.Logged]
  },
  {
    path: 'coletas',
    loadChildren: () => import('./pages/coletas/coletas.module').then(m => m.ColetasPageModule),
    canActivate: [AuthGuard.Logged]
  },
  {
    path: 'coletores',
    loadChildren: () => import('./pages/coletores/coletores.module').then( m => m.ColetoresPageModule),
    canActivate: [AuthGuard.Logged]
  },
  {
    path: 'testemunhas',
    loadChildren: () => import('./pages/testemunhas/testemunhas.module').then( m => m.TestemunhasPageModule),
    canActivate: [AuthGuard.Logged]
  },
  {
    path: 'laudo-toxicologico/:type',
    loadChildren: () => import('./pages/laudos/laudos.module').then( m => m.LaudosPageModule),
    canActivate: [AuthGuard.Logged]
  },
  {
    path: 'configuracoes',
    loadChildren: () => import('./pages/configuracoes/configuracoes.module').then( m => m.ConfiguracoesPageModule),
    canActivate: [AuthGuard.Logged]
  },
  {
    path: 'laudo-medico/:type',
    loadChildren: () => import('./pages/laudos-medicos/laudos-medicos.module').then( m => m.LaudosMedicosPageModule),
    canActivate: [AuthGuard.Logged]
  },
  {
    path: 'financeiro',
    loadChildren: () => import('./pages/financeiro/financeiro.module').then(m => m.FinanceiroPageModule)
  },
  {
    path: 'lotes',
    loadChildren: () => import('./pages/lotes/lotes.module').then( m => m.LotesPageModule)
  },
  {
    path: '**',
    redirectTo: 'coletas'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
