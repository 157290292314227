import {Injectable, OnInit} from "@angular/core";
import Api from "../helpers/api";
import {ModalController, NavParams} from "@ionic/angular";
import {ListaUsuariosPage} from "./usuarios/modals/lista-usuarios/lista-usuarios.page";
import {EditarUsuarioPage} from "./usuarios/modals/editar-usuario/editar-usuario.page";
import {ProcurarGenericoComponent} from "../components/procurar-generico/procurar-generico.component";
import {AdicionarGenericoComponent} from "../components/adicionar-generico/adicionar-generico.component";
import * as moment from "moment";
import {ActivatedRoute} from "@angular/router";
import me from "../helpers/me";
import App from "../helpers/app";

@Injectable()
export class Base implements OnInit {
    labels = {
        novo: 'Novo',
        procurar: 'Procurar'
    };

    modals = {
        procurar: {
            titulo: 'Procurar',
            fields: [
                // {name: 'Nome', field: 'nome'}
            ]
        },
        editar: {
            titulo: '',
            fields: [],
            foto: null
        }
    };


    route = '';
    items = [];
    mobile = false;

    filters:any = {};

    public master = false;

    constructor(protected api:Api, protected modalController: ModalController, protected activedRoute: ActivatedRoute, protected me: me, protected navParams: NavParams, protected app: App) {
        this.mobile = window.innerWidth < 1024;
        this.me.hasPermission(`MASTER`).then(bool => this.master = bool);
    }

    ngOnInit() {
        this.get();
    }

    offset = 0;

    fixedFilters = [];

    async get(change_filters = true){
        const self = this;
        const filters = [];

        for(let item of self.fixedFilters){
            filters.push(item);
        }

        if(change_filters){
            for(let item in self.filters){
                console.log(`item`, item, self.filters);

                if(self.filters[item]){
                    if(typeof self.filters[item] === 'string' && (self.filters[item].indexOf('/') > -1 || self.filters[item].indexOf('-') > -1) &&   moment(self.filters[item]).isValid()){
                        filters.push({
                            [item]: {
                                'Op.and': [
                                    {'Op.gte': moment(self.filters[item]).format('YYYY-MM-DD 00:00:00')},
                                    {'Op.lte': moment(self.filters[item]).format('YYYY-MM-DD 23:59:59')},
                                ]
                            }
                        });
                    }
                    else if(typeof self.filters[item] === "string"){
                        filters.push({
                            [item]: {
                                'Op.or': [
                                    {'Op.like': `%${self.filters[item].split(" ").join("%")}%`},
                                    {'Op.like': `${self.filters[item].split(" ").join("%")}`}
                                ]
                            }
                        });
                    }
                    else{
                        filters.push({[item]: self.filters[item]});
                    }
                }
            }

            console.log(`self.filters`, filters);
            // self.filters = filters;
        }

        let finalFilter:any = {};

        for(let item of filters){
            console.log(item, Object.keys(item));

            const key = Object.keys(item)[0];
            const value = Object.values(item)[0];

            if(key.indexOf('.') > -1){
                if(!finalFilter.where){
                    finalFilter.where = {}
                }
                if(!finalFilter[key.split('.')[0]]){
                    finalFilter.where[key.split('.')[0]] = {'Op.and': []}
                    finalFilter.where[key.split('.')[0]]['Op.and'].push({[key.split('.')[1]]: value});
                }else {
                    finalFilter.where[key.split('.')[0]]['Op.and'].push({[key.split('.')[1]]: value});
                }
            }
        }

        if(Object.keys(finalFilter).length === 0){
            finalFilter = {where: {'Op.and': filters}}
        }


        const api = this.api.new();
        api.post(self.route + '/search/0/' + (this.offset || 100), finalFilter).subscribe(data => {
            this.items = (data.success) ? self.foreachItems(data.return.rows) : [];
            this.offset = 0;
            this.itemsLoaded();
        });
    }

    itemsLoaded(){

    }

    async getMore(event){
        try{
            this.offset += 100;

            const self = this;
            const filters = self.filters;

            let finalFilter:any = {};

            for(let item of filters){
                console.log(item, Object.keys(item));

                const key = Object.keys(item)[0];
                const value = Object.values(item)[0];

                if(key.indexOf('.') > -1){
                    if(!finalFilter.where){
                        finalFilter.where = {}
                    }
                    if(!finalFilter[key.split('.')[0]]){
                        finalFilter.where[key.split('.')[0]] = {'Op.and': []}
                        finalFilter.where[key.split('.')[0]]['Op.and'].push({[key.split('.')[1]]: value});
                    }else {
                        finalFilter.where[key.split('.')[0]]['Op.and'].push({[key.split('.')[1]]: value});
                    }
                }
            }

            if(Object.keys(finalFilter).length === 0){
                finalFilter = {where: {'Op.and': filters}}
            }

            const api = this.api.new();
            api.post(self.route + '/search/' + this.offset + '/100', finalFilter).subscribe(
                data => {
                    if(data.success){
                        self.items = self.items.concat((data.success) ? self.foreachItems(data.return.rows) : []);
                        if(data.return.rows < 20){
                            event.target.disabled = true;
                        }
                        event.target.complete()
                    }else{
                        event.target.complete()
                    }
                },
                error => {}
            );
        }catch (e) {
            event.target.complete()
        }
    }

    foreachItems(items: any[]){
        return items;
    }

    async search() {
        this.filters = {};
        const modal = await this.modalController.create({
            component: ProcurarGenericoComponent,
            componentProps: {
                'filters': this.filters,
                'fields': this.modals.procurar.fields,
                'titulo': this.modals.procurar.titulo
            }
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();

        console.log(`data.filters`, data.filters);
        if(data && data.filters){
            this.filters = data.filters;
            this.get();
        }
    }

    async editar(id: number | null, item ?: any, canEdit = true) {
        return new Promise(async resolve => {
            const modal = await this.modalController.create({
                component: AdicionarGenericoComponent,
                componentProps: {
                    'id': id,
                    'item': Object.assign({},item),
                    'route': this.route,
                    'foto': this.modals.editar.foto,
                    'fields': this.modals.editar.fields,
                    'titulo': this.modals.editar.titulo,
                    'readonly': !canEdit
                }
            });
            await modal.present();
            resolve(await modal.onWillDismiss());
            this.get(false);
        })
    }
}
