import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import Api from "../../../../helpers/api";
import App from "../../../../helpers/app";
import me from "../../../../helpers/me";

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.page.html',
  styleUrls: ['./editar-usuario.page.scss'],
})
export class EditarUsuarioPage implements OnInit {
  public id = null;
  public item: any = {
    id: '',
    name: '',
    picture: '',
    username: '',
    password: '',
    email: '',
    admin: false,
    firebaseToken: '',
    prov_password: '',
    status: '',
    postos: [],
    PostoColetaUsers: [],
    permissons: {}
  };

  master = false;

  segment = 'usuario';
  postoSelecionado;

  constructor(private modalController: ModalController, private navParams: NavParams, private api: Api, private app: App, private me: me) {
    this.me.hasPermission(`MASTER`).then(bool => this.master = bool);

    if(navParams.get('id')){
      this.id = navParams.get('id');
    }

    if(navParams.get('item')){
      this.item = navParams.get('item');
    }

    if(!this.item.postos){
      this.item.postos = [];
    }

    for(let item of this.item.PostoColetaUsers || []){
      const posto = item.PostoColeta;
      if(item.admin){posto.admin = true;}
      this.item.postos.push(posto);
    }
  }

  ngOnInit() {
  }

  posto(event){
    this.postoSelecionado = event;
  }

  dismiss(){
    this.modalController.dismiss();
  }

  action(){
    const self = this;
    const api = self.api.new().set('success', true);

    if(self.id){
      api.put('users/' + self.id, self.item).subscribe(data => {
        if(data.success){
          self.modalController.dismiss();
        }
      })
    }else{
      api.post('users/', self.item).subscribe(data => {
        if(data.success){
          self.modalController.dismiss();
        }
      })
    }

  }

  async delete() {
    const self = this;

    if(await self.app.confirm()){
      const api = self.api.new().set('success', true);
      api.delete('users/' + self.id).subscribe(data => {
        if(data.success){
          self.modalController.dismiss();
        }
      })
    }
  }

  changeListener($event) : void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const self = this;
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();

    myReader.onloadend = (e) => {
      self.item.picture = myReader.result as any;
    }
    myReader.readAsDataURL(file);
  }

  adicionarPosto(){
    const self = this;
    if(self.postoSelecionado){

      for(let i in self.item.postos){
        const item = self.item.postos[i];
        if(item.id == self.postoSelecionado.id){
          return self.item.postos[i] = self.postoSelecionado;
        }
      }


      self.item.postos.push(self.postoSelecionado);
    }
  }

  deletarPosto(index){
    this.item.postos.splice(index, 1);
  }
}
