import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";

@Component({
  selector: 'postos-lista',
  templateUrl: './lista.page.html',
  styleUrls: ['./lista.page.scss'],
})
export class ListaPage implements OnInit {

  public filters = {
    codigo: '',
    nome: '',
    cnpj: '',
    telefones: '',
    email: '',
    cidade: '',
    responsavel: ''
  };

  constructor(private modalController: ModalController, private navParams: NavParams) {
    this.filters = navParams.get('params');
  }

  ngOnInit() {
  }

  dismiss(){
    this.modalController.dismiss();
  }

  search(){
    this.modalController.dismiss({
      filters: this.filters
    });
  }
}
