import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import Api from "../../helpers/api";
import App from "../../helpers/app";
import * as moment from "moment";
import Me from "../../helpers/me";

@Component({
  selector: 'app-adicionar-generico',
  templateUrl: './adicionar-generico.component.html',
  styleUrls: ['./adicionar-generico.component.scss'],
})
export class AdicionarGenericoComponent implements OnInit {
  public id = null;
  public item: any = {};

  public filters = {};

  public route = '';
  public titulo = '';
  public fields = [];
  public foto = null;
  public readonly = false;

  public moment = moment;

  admin = false;

  constructor(private modalController: ModalController, private navParams: NavParams, private api: Api, private app: App, private me: Me) {
    if(navParams.get('route')){
      this.route = navParams.get('route');
    }
    if(navParams.get('id')){
      this.id = navParams.get('id');
    }
    if(navParams.get('readonly')){
      this.readonly = navParams.get('readonly');
    }

    if(navParams.get('item')){
      this.item = navParams.get('item');
    }

    if(navParams.get('foto')){
      this.foto = navParams.get('foto');
    }

    this.filters = navParams.get('params');
    this.titulo = navParams.get('titulo');
    this.fields = navParams.get('fields');
    this.me.hasPermission('MASTER').then(admin => this.admin = admin);
  }

  ngOnInit() {
  }

  dismiss(){
    this.modalController.dismiss();
  }

  action(){
    const self = this;
    const api = self.api.new().set('success', true);

    if(self.id){
      api.put(self.route + '/' + self.id, self.item).subscribe(data => {
        if(data.success){
          self.modalController.dismiss(data.return);
        }
      })
    }else{
      api.post(self.route + '/', self.item).subscribe(data => {
        if(data.success){
          self.modalController.dismiss(data.return);
        }
      })
    }

  }

  async delete() {
    const self = this;

    if(await self.app.confirm(`Apagar`, `Você tem certeza de que deseja apagar?`)){
      const api = self.api.new().set('success', true);
      api.delete(self.route + '/' + self.id).subscribe(data => {
        if(data.success){
          self.modalController.dismiss();
        }
      })
    }
  }

  changeListener($event, field) : void {
    if(field){
      this.readThis($event.target, field);
    }
  }

  readThis(inputValue: any, field: string): void {
    const self = this;
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();

    myReader.onloadend = (e) => {
      self.item[field] = myReader.result as any;
    }
    myReader.readAsDataURL(file);
  }

  postoSelecionado(posto, field){
    if(posto && 'id' in posto){
      this.item[field] = posto.id;
    }
  }
  selecionado(item, field){
    if(item){
      this.item[field] = item.id;
    }
  }

  addColeta(f, value){
    this.item[f.field].push(Object.assign({}, value));
  }

  obterCep(cep: string, item){
    const self = this;

    /*
        bairro: "Niterói"
        cep: "92120-210"
        complemento: ""
        gia: ""
        ibge: "4304606"
        localidade: "Canoas"
        logradouro: "Rua Marechal Rondon"
        uf: "RS"
        unidade: ""
    */

    if(cep){
      self.api.get(`https://viacep.com.br/ws/${(cep || '').replace(/[^0-9]/g,'')}/json/`).then((data: any) => {
        console.log(`data`, data);
        if(data.cep){
          item.endereco = data.logradouro;
          item.bairro = data.bairro;
          item.cidade = data.localidade;
          item.estado = data.uf;
        }
      })
    }
  }

  can(){
    const self = this;
    for(let f of self.fields){
      if(f.required && (self.item[f.field] == null || self.item[f.field] === '')){
        return false;
      }
    }

    return true;
  }

  push(array, object){
    array.push(Object.assign({}, object));
  }

  Object = Object;
  CqiInicial:any = {};
  CqiFinais:any = {};
  Equipamento:any = {};
}
