import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DoadoresPageRoutingModule } from './doadores-routing.module';

import { DoadoresPage } from './doadores.page';
import {CommonModuleModule} from "../../modules/common-module/common-module.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        DoadoresPageRoutingModule,
        CommonModuleModule
    ],
    exports: [
        DoadoresPage
    ],
    declarations: [DoadoresPage]
})
export class DoadoresPageModule {}
