import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PostosPageRoutingModule } from './postos-routing.module';

import { PostosPage } from './postos.page';
import {EditarPage} from './modals/editar/editar.page';
import {ListaPage} from './modals/lista/lista.page';
import {UcwordsPipe} from "../../pipes/ucwords/ucwords.pipe";
import {CommonModuleModule} from "../../modules/common-module/common-module.module";
import {ListaPageModule} from "./modals/lista/lista.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PostosPageRoutingModule,
        CommonModuleModule,
    ],
    exports: [
        PostosPage
    ],
    declarations: [PostosPage]
})
export class PostosPageModule {}
