import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {retry, timeout} from 'rxjs/operators';

import config from '../config';
import App from './app';
import {Observable, Observer, Subject} from 'rxjs';
import {AlertController, LoadingController} from '@ionic/angular';

export type Callback = (response: Response) => void;

export interface ResponseInterface {
    readonly api;
    readonly code: number;
    readonly message: string;
    readonly return: any;
    readonly error: boolean;
    readonly error_stack: any;
}

const LOGIN_ERROR = 401;
const LOG_TITLE = 'app/helpers/api.ts';

function getValidLink(link: string) {
    return (link.substr(link.length - 1, 1) === '/') ? link : link + '/';
}

@Injectable()
export default class Api {
    public url: string;
    public development: boolean;

    constructor(readonly http: HttpClient, public app: App, public loadingController: LoadingController) {
        const self = this;
        const condition = document.URL.indexOf('localhost') > -1;

        self.url = condition
            ? getValidLink(config.api.development_link)
            : getValidLink(config.api.production_link);
        self.development = condition;
    }

    public new() {
        return new Io(this);
    }

    public get(url){
        const self = this;
        return new Promise((resolve, reject) => {
            self.http
                .get(url)
                .pipe(retry(5))
                .pipe(timeout(30000))
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => {
                        reject(error)
                    }
                );
        })
    }
}

export class Io {
    public retry = 5;
    public timeout = 90 * 1000;

    public success = false;
    public log = false;
    public error = true;
    public loader = true;
    public exception = true;

    public _loader = new Subject<boolean>();
    public loading: boolean = false;

    public loaderCtrl;

    private headers: HttpHeaders;

    constructor(private base: Api) {
        const self = this;

        self._loader.subscribe(async bool => {
            self.loading = bool;

            if(!self.loaderCtrl){
                self.loaderCtrl = await self.base.loadingController.create();
            }

            if(self.loading && self.loader){
                await self.loaderCtrl.present();
            }else{
                await self.loaderCtrl.dismiss();
            }
        });
    }

    public set(field: 'log' | 'error' | 'exception' | 'success' | 'loader', value: boolean) {
        this[field] = value;
        return this;
    }
    public silent(bool = true) {
        this.set('error', !bool);
        this.set('exception', !bool);
        return this;
    }

    public async goToLink(url, args ?: { [a: string]: any }) {
        const self = this;
        let params = "";

        if(!args){ args = {}; }

        try {
            const user = JSON.parse(await localStorage.getItem(config.me.cache_name) || '{}');
            args.authorization = 'Basic ' + btoa(user.username + ':' + user.password);
        }catch (e) {

        }

        for (var key in args) {
            if (params != "") {
                params += "&";
            }
            params += key + "=" + encodeURIComponent(args[key]);
        }
        self.headers = new HttpHeaders({'Content-Type': 'application/json'});

        window.location.href = ((url.indexOf('http') == -1 ? self.base.url : '') + url + '?' + params);
    }

    public get(url, args ?: { [a: string]: any }): Observable<Response> {
        const self = this;

        return Observable.create( async (observer: Observer<Response>) => {
            await self.setHeaders();

            self._loader.next(true);

            self.base.http
                .get((url.indexOf('http') === -1) ?self.base.url + url : url, {headers: self.headers, params: args || {}})
                .pipe(retry(self.retry))
                .pipe(timeout(self.timeout))
                .subscribe(
                    (data) => {
                        self._loader.next(false);
                        self.responseHttpOk(data, observer);
                    },
                    (error) => {
                        self._loader.next(false);
                        self.responseHttpError(error, observer);
                    }
                );
        });
    }
    public post(url, args ?: { [a: string]: any }): Observable<Response> {
        const self = this;

        return Observable.create( async (observer: Observer<Response>) => {
            await self.setHeaders();

            self._loader.next(true);

            self.base.http
                .post(self.base.url + url, args || {}, {headers: self.headers})
                .pipe(retry(self.retry))
                .pipe(timeout(self.timeout))
                .subscribe(
                    (data) => {
                        self._loader.next(false);
                        self.responseHttpOk(data, observer);
                    },
                    (error) => {
                        self._loader.next(false);
                        self.responseHttpError(error, observer);
                    }
                );
        });
    }
    public put(url, args ?: { [a: string]: any }): Observable<Response> {
        const self = this;

        return Observable.create( async (observer: Observer<Response>) => {
            await self.setHeaders();

            self._loader.next(true);

            self.base.http
                .put(self.base.url + url, args || {}, {headers: self.headers})
                .pipe(retry(self.retry))
                .pipe(timeout(self.timeout))
                .subscribe(
                    (data) => {
                        self._loader.next(false);
                        self.responseHttpOk(data, observer);
                    },
                    (error) => {
                        self._loader.next(false);
                        self.responseHttpError(error, observer);
                    }
                );
        });
    }
    public delete(url, args ?: { [a: string]: any }): Observable<Response> {
        const self = this;

        return Observable.create( async (observer: Observer<Response>) => {
            await self.setHeaders();

            self._loader.next(true);

            self.base.http
                .delete(self.base.url + url, {headers: self.headers, params: args || {}})
                .pipe(retry(self.retry))
                .pipe(timeout(self.timeout))
                .subscribe(
                    (data) => {
                        self._loader.next(false);
                        self.responseHttpOk(data, observer);
                    },
                    (error) => {
                        self._loader.next(false);
                        self.responseHttpError(error, observer);
                    }
                );
        });
    }

    private responseHttpOk(data: any, observer: Observer<Response>){
        const self = this;
        const response = (new Response()).build(data);

        if (self.log) {
            console.log(LOG_TITLE, response, this);
        }

        if (response.error === false) {
            if(self.success){
                self.alertSuccess(response);
            }
            return observer.next(response);
        }

        if (self.error) {
            self.alertError(response);
        }

        if (self.exception) {
            observer.error(response);
        } else {
            observer.next(response);
        }
    }
    private responseHttpError(error: any, observer: Observer<Response>){
        const self = this;

        console.error(LOG_TITLE, error, this);

        const response = new Response();

        if(error.error){
            response.build(error.error);
        }

        if (+error.status === LOGIN_ERROR) {
            localStorage.removeItem(config.me.cache_name + '_token');
            localStorage.removeItem(config.me.cache_name);
            return self.alertUnauthorized();
        }

        if (self.error) {
            self.alertError(response);
        }


        if (self.exception) {
            observer.error(response);
        } else {
            observer.next(response);
        }
    }

    private async alertSuccess(data: Response) {
        return await this.base.app.alert(
            'Sucesso',
            data.message || 'Atividade realizada com sucesso!'
        )
    }
    private async alertError(data: Response) {
        console.log(`error_alert`, data);

        const message = data.message;

        return await this.base.app.alert(
            'Erro',
            (message && typeof message == 'string')
                ? message
                : 'Erro de comunicação com o servidor - ' + JSON.stringify(data || {})
        )
    }
    private async alertUnauthorized() {
        return await this.base.app.alert(
            'Erro',
            'Você não tem permissões para visualizar essa página'
        ).then(() => window.location.href = 'login');
    }

    private async setHeaders() {
        const self = this;
        self.headers = new HttpHeaders({'Content-Type': 'application/json'});

        try{
            const user = JSON.parse(await localStorage.getItem(config.me.cache_name) || '{}');
            console.log(`user`, user);
            if(user){
                self.headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(user.username + ':' + user.password)
                });
            }
        }catch (e) {
            console.error(LOG_TITLE, `setHeaders()`, e);
        }
    }
}

export class Response implements ResponseInterface {
    readonly api;
    readonly code: number;
    readonly message: string;
    readonly return: any;
    readonly error: boolean;
    readonly error_stack: any;

    public build(data: any){
        for (const item in data) {
            this[item] = data[item];
        }
        return this;
    }

    public get success() {
        return (this.error === false);
    }
}
