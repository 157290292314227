import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";

@Component({
  selector: 'app-lista-usuarios',
  templateUrl: './lista-usuarios.page.html',
  styleUrls: ['./lista-usuarios.page.scss'],
})
export class ListaUsuariosPage implements OnInit {

  public filters = {
    email: '',
    name: '',
    phone: ''
  };

  constructor(private modalController: ModalController, private navParams: NavParams) {
    this.filters = navParams.get('params');
  }

  ngOnInit() {
  }

  dismiss(){
    this.modalController.dismiss();
  }

  search(){
    this.modalController.dismiss({
      filters: this.filters
    });
  }
}
