import { Component, OnInit } from '@angular/core';

import {ModalController, Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import App from './helpers/app';
import Me from './helpers/me';
import {Route, Router} from '@angular/router';
import {AdicionarGenericoComponent} from "./components/adicionar-generico/adicionar-generico.component";
import {InformacoesComponent} from "./pages/laudos/components/informacoes/informacoes.component";
import {RevisorComponent} from "./pages/coletas/components/revisor/revisor.component";
import {EditProfileComponent} from "./modals/edit-profile/edit-profile.component";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      header: 'Pessoas'
    },
    {
      title: 'Usuários do Sistema',
      url: '/usuarios/',
      icon: 'people',
      master: true
    },
    {
      title: 'Doadores / Clientes',
      icon: 'person',
      url: '/doadores/'
    },
    {
      title: 'Testemunhas',
      icon: 'person-add',
      url: 'testemunhas/'
    },
    {
      title: 'Coletores',
      icon: 'person-add',
      url: 'coletores/'
    },
    {
      header: 'Coletas'
    },
    {
      title: 'Postos de Coleta',
      icon: 'business',
      url: '/postos/'
    },
    {
      title: 'Coletas Realizadas',
      icon: 'attach',
      children: [
        {title: 'Ver Coletas', url: '/coletas/'},
        {title: 'Revisão', click: 'coletas-revisor', master: true},
      ]
    },
    {
      header: 'Laudos'
    },
    {
      title: 'Laudo Médico',
      icon: 'document',
      children: [
        {title: 'Em Espera', url: '/laudo-medico/em-espera'},
        {title: 'Liberados', url: '/laudo-medico/liberado'},
      ]
    },
    {
      title: 'Laudo Toxicológico',
      icon: 'newspaper',
      children: [
        {title: 'Em Espera', url: '/laudo-toxicologico/em-espera'},
        {title: 'Liberados', url: '/laudo-toxicologico/liberado'},
        {title: 'Informações do Laudo', click: 'laudo-toxicologico-informacoes', url: false, master: true},
      ]
    },
    {
      title: 'Lotes',
      icon: 'construct-outline',
      url: '/lotes/',
      master: true
    },
    {
      header: 'Sistema'
    },
    // {
    //   title: 'Configurações',
    //   icon: 'cog',
    //   url: '/configuracoes/'
    // },
  ];

  master = false;
  postoAdmin = false;
  user:any = {};

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private app: App,
    private me: Me,
    private router: Router,
    private modalController: ModalController
  ) {
    const self = this;
    self.initializeApp();
    self.me.hasPermission('MASTER').then(bool => self.master = bool);
    self.me.getData().data.subscribe(user => {
      self.user = user;
      if(user){
        for(let u of user.PostoColetaUsers){
          self.postoAdmin = true;
        }
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  async logout(){
    const self = this;
    if(await self.app.confirm(`Sair`, `Você tem certeza de que deseja sair do sistema?`)){
      await self.me.logout();
      self.router.navigateByUrl('/login');
    }
  }

  async functions(action){
    if(action=='laudo-toxicologico-informacoes'){

      const modal = await this.modalController.create({
        component: InformacoesComponent,
        componentProps: {
        }
      });
      await modal.present();
      await modal.onWillDismiss();
    }

    if(action == 'coletas-revisor'){

      const modal = await this.modalController.create({
        component: RevisorComponent,
        componentProps: {
        }
      });
      await modal.present();
      await modal.onWillDismiss();
    }
  }

 async editProfile(){

    const modal = await this.modalController.create({
      component: EditProfileComponent,
      componentProps: {
      }
    });
    await modal.present();
    await modal.onWillDismiss();
   this.me.get().subscribe(user => this.user = user);
  }
}
