import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Api from "../../helpers/api";
import {Base} from "../../pages/base.crud";
import {DoadoresPage} from "../../pages/doadores/doadores.page";

@Component({
  selector: 'app-clientes-select',
  templateUrl: './clientes-select.component.html',
  styleUrls: ['./clientes-select.component.scss'],
})
export class ClientesSelectComponent extends DoadoresPage {

  filters = {
    codigo: '',
    nome: ''
  };
  items = [];
  index = -1;

  @Output() selected = new EventEmitter();
  @Input() value = null;
  @Input() disabled = false;
  @Input() postoId = null;
  @Input() canAdd = false;

  emit(){
    this.selected.emit(this.items[this.index]);
  }

  ngOnChanges() {
    const self = this;
    self.items = [];
    self.get().then(data => {
      self.selected.emit(self.items[self.index]);
    })
  }

  async get(){
    const self = this;
    const filters = [];

    if(self.postoId){
      self.filters['postoId'] = self.postoId;
    }

    for(let item in self.filters){
      if(self.filters[item]){
        filters.push({

          [item]: (typeof self.filters[item] !== 'string') ?  self.filters[item] : {
            'Op.or': [
              {'Op.like': `%${self.filters[item].split(" ").join("%")}%`},
              {'Op.like': `${self.filters[item].split(" ").join("%")}`}
            ]
          }
        });
      }
    }
    const api = this.api.new();
    api.post('clientes/search/0/200', Object.values(filters). length == 0 ? {} : {where: {'Op.and': filters}}).subscribe(data => {
      this.items = (data.success) ? data.return.rows : [];
      if(self.value){
        api.get('clientes/' + self.value.id).subscribe(data => {
          self.items.push(data.return);
          self.index = self.items.length-1;
        });
      }
    });
  }

  async editar(id: number | null, obj ?: any) {
    const self = this;
    if(!id){
      const c:any = await super.editar(id, obj);
      if(c.data){
        const api = this.api.new();
        api.get('clientes/' + c.data.id).subscribe(data => {
          self.items.push(data.return);
          self.index = self.items.length-1;
        });
      }
      return c;
    }else{
      const api = this.api.new();
      api.get('clientes/' + id).subscribe(data => {
        return super.editar(id, data.return);
      });
    }
  }

  novo(){
    const self = this;
    // editar(null, {postoId: postoId}

    if(self.postoId){
      const api = this.api.new();
      api.get('postos/' + self.postoId).subscribe(data => {
        return self.editar(null, {postoId: self.postoId, PostoColeta: data.return});
      });
    }else{
      return self.editar(null, {});
    }
  }
}
