import {Injectable} from '@angular/core';
import {AlertController, LoadingController} from '@ionic/angular';

@Injectable()
export default class App {
    constructor(public alertCtrl: AlertController, public loadingController: LoadingController) {}

    async alert(title: string, message: string) {
        return (await this.alertCtrl.create({
            header: title,
            message: message,
            buttons: ['OK']
        })).present();
    }

    confirm(title: string = 'Confirmação', message: string = 'Você tem certeza de que deseja fazer isso?', confirmButtonText = 'OK', cancelButtonText = 'Cancelar') {
        const self = this;
        return new Promise( async resolve => {

            const alert = await self.alertCtrl.create({
                header: title,
                message: message,
                buttons: [
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: (blah) => {
                            resolve(false)
                        }
                    }, {
                        text: 'OK',
                        handler: () => {
                            resolve(true)
                        }
                    }
                ]
            });

            await alert.present();
        });
    }

    async loader(){
        const loader = await this.loadingController.create();
        loader.present();

        return loader;
    }
}
