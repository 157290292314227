import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Api from "../../helpers/api";
import {ModalController} from "@ionic/angular";
import {EditarPage} from "../../pages/postos/modals/editar/editar.page";

@Component({
  selector: 'postos-de-coleta-select',
  templateUrl: './postos-de-coleta-select.component.html',
  styleUrls: ['./postos-de-coleta-select.component.scss'],
})
export class PostosDeColetaSelectComponent implements OnInit {

  filters = {
    codigo: '',
    nome: '',
    cnpj: '',
    telefones: '',
    email: '',
    cidade: '',
    responsavel: ''
  };
  items = [];
  index = -1;
  addAsAdmin = false;

  @Input() all = false;
  @Input() admin = false;
  @Input() disabled = false;
  @Input() value = null;
  @Output() selected = new EventEmitter();

  constructor(private api:Api, private modalController: ModalController) {
  }

  emit(){
    const item = {... {admin: false}, ...this.items[this.index]};

    if(this.addAsAdmin){
      item.admin = true;
    }

    console.log(`item`, item, this.addAsAdmin);
    this.selected.emit(item);
  }

  ngOnInit() {
    const self = this;
    self.get().then(data => {
      self.selected.emit(self.items[self.index]);
    })
  }

  async get(){
    const self = this;
    const filters = [];

    for(let item in self.filters){
      if(self.filters[item]){
        filters.push({
          [item]: {
            'Op.or': [
              {'Op.like': `%${self.filters[item].split(" ").join("%")}%`},
              {'Op.like': `${self.filters[item].split(" ").join("%")}`}
            ]
          }
        });
      }
    }
    const api = this.api.new();
    api.post('postos/search/0/200', Object.values(filters). length == 0 ? {} : {where: {'Op.and': filters}}).subscribe(data => {
      this.items = (data.success) ? data.return.rows : [];
      if(self.value){
        self.items.push(self.value);
        self.index = self.items.length-1;
      }else{
        if(this.items.length == 1){
          self.index = 0;
        }
      }
    });
  }

  async ver(){
    const self = this;
    const item = self.items[self.index];
    const modal = await this.modalController.create({
      component: EditarPage,
      componentProps: {'id': item.id, 'item': item, 'edit': false}
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    // this.get();
  }
}
