import {Component, OnInit} from '@angular/core';
import {Base} from "../base.crud";
import * as moment from "moment";
import {AdicionarGenericoComponent} from "../../components/adicionar-generico/adicionar-generico.component";
import {OpcoesComponent} from "./components/opcoes/opcoes.component";
import {Params} from "@angular/router";

@Component({
    selector: 'app-coletas',
    templateUrl: './coletas.page.html',
    styleUrls: ['./coletas.page.scss'],
})
export class ColetasPage extends Base {
    route = 'coletas';
    moment = moment;

    Object = Object;

    id = null;

    isObj(x) {
        return typeof x === 'object';
    }

    ngOnInit() {
        const self = this;
        super.ngOnInit();

        this.activedRoute.params.subscribe((params: Params) => {
            self.id = params['id'];
            if(self.id){
                self.opcoes({id: self.id});
            }
        });

        this.modals.procurar.fields = [
            {name: 'FCC', field: 'ordemServico'},
            {name: 'Data de Coleta', field: 'dataColeta', type: 'date'},
            {name: 'Posto de Coleta', field: 'postoId', type: 'posto', required: true},
            {name: 'Finalidade', field: 'finalidade', type: 'finalidade', required: true},
            {name: 'Número do Registro', field: 'numeroRegistro', required: true},
            {name: 'Cliente', field: 'clienteId', type: 'cliente', required: true},
            {name: 'Nº Código de Barras', field: 'ordemServico', required: true},
            {
                name: 'Amostra Coletada',
                type: 'radio',
                subitems: ['Cabelos', 'Pelos'],
                field: 'amostraColetada',
                required: true
            },
            {
                name: 'Local da Amostra Coletada',
                type: 'radio',
                subitems: ['Cabeça'],
                field: 'amostraLocal',
                required: true
            },
            {
                name: 'Cor da Amostra Coletada', type: 'radio', subitems: [
                    'Castanho claro', 'Castanho escuro', 'Preto', 'Loiro', 'Ruivo', 'Branco', 'Grisalho'
                ], field: 'amostraCor', required: true
            },
            {name: 'Tamanho Amostra', field: 'amostraTamanhoCabelo', placeholder: 'cm (Mín. 3,0cm)', required: true},
            {
                name: 'Tratamento químico capilar?',
                type: 'radio',
                subitems: ['Não', 'Sim'],
                field: 'tratamentoQuimico',
                required: true
            },
            {
                name: 'Utilizou algum medicamento interferente nos últimos 6 meses?',
                type: 'radio',
                subitems: ['Não', 'Sim'],
                field: 'medicamentoInterferente',
                required: true
            },

            {
                name: 'Relato de substâncias',
                field: 'relatoSubstancias',
                placeholder: 'Relato de substâncias ilícitas, periódo e frequência de uso.'
            },

            {name: 'Coletor', field: 'coletorId', type: 'coletor', required: true},
            {name: 'Testemunha', field: 'testemunhaId', type: 'testemunha', required: true},
        ];

        this.modals.editar.fields = [
            // { name: 'Data de Coleta', field: 'dataColeta', type: 'dataColeta', required: true },
            {name: 'Lote', value: 'Lote', field: 'loteId', type: 'lote', required: false, canAdd: true},
            {name: 'Posto de Coleta', value: 'PostoColeta', field: 'postoId', type: 'posto', required: true},
            {name: 'Cliente', value: 'Cliente', field: 'clienteId', type: 'cliente', required: true, canAdd: true},
            {name: 'Finalidade', field: 'finalidade', type: 'finalidade', required: true},
            {name: 'Nº Código de Barras', field: 'ordemServico', required: true},
            {
                name: 'Amostra Coletada',
                type: 'radio',
                subitems: ['Cabelos', 'Pelos'],
                field: 'amostraColetada',
                required: true
            },
            {
                name: 'Local da Amostra Coletada',
                type: 'radio',
                subitems: ['Cabeça', 'Axila', 'Tórax', 'Braço', 'Perna', 'Costas', 'Pubianos'],
                field: 'amostraLocal',
                required: true
            },
            {
                name: 'Cor da Amostra Coletada', type: 'radio', subitems: [
                    'Castanho claro', 'Castanho escuro', 'Preto', 'Loiro', 'Ruivo', 'Branco', 'Grisalho'
                ], field: 'amostraCor', required: true
            },
            {name: 'Tamanho Amostra', field: 'amostraTamanhoCabelo', placeholder: 'cm (Mín. 3,0cm)', required: true},

            {
                name: 'Grupo de drogas a serem analisadas',
                type: 'ion-text',
                text: 'Anfetamina; metanfetamina; anfepramona; MDMA (ecstasy); MDA; femproporex; mazindol; cocaína; AEME (crack); benzoilecgonina; cocaetileno (cocaína + álcool); norcocaína; 6-acetilmorfina; codeína; morfina; THC; carboxy THC (THCCOOH)'
            },


            {
                name: 'Tratamento químico capilar?',
                type: 'radio',
                subitems: ['Não', 'Sim'],
                field: 'tratamentoQuimico',
                required: true
            },
            {
                name: 'Utilizou algum medicamento interferente nos últimos 6 meses?',
                type: 'radio',
                subitems: ['Não', 'Sim'],
                field: 'medicamentoInterferente',
                required: true
            },

            {
                name: 'Relato de substâncias',
                field: 'relatoSubstancias',
                placeholder: 'Relato de substâncias ilícitas, periódo e frequência de uso.'
            },

            {name: 'Coletor', value: 'Coletor', field: 'coletorId', type: 'coletor', required: true},
            {name: 'Testemunha', value: 'Testemunha', field: 'testemunhaId', type: 'testemunha', required: true},
            {name: 'Valor Nota Fiscal', field: 'valorNF', required: false},
        ]
    }


    async opcoes(item: any) {
        const self = this;
        const modal = await this.modalController.create({
            component: OpcoesComponent,
            componentProps: {
                'item': Object.assign({}, item),
                'page': self
            }
        });
        await modal.present();
        await modal.onWillDismiss();
        this.get();
    }

    async editar(id: number | null, item?: any, canEdit: boolean = true) {
        const c:any = await super.editar(id, item, canEdit);

        if(!id && c.data){
            window.open(this.api.url + 'coletas/' + c.data.id + '/pdf');
        }

        this.opcoes(c.data);

        return c;
    }
}
