import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import Me from "../../helpers/me";
import App from "../../helpers/app";
import {Router} from "@angular/router";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {

  item: any = {
    picture: ''
  };
  constructor(public modalController: ModalController, public me: Me, public app: App, public router: Router) {
    this.me.get().subscribe(user => {
      if(user){
        this.item = user;
        this.item.reppassword = user.password;
      }
    });
  }

  ngOnInit() {}

  dismiss(){
    this.modalController.dismiss();
  }

  changeListener($event) : void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const self = this;
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();

    myReader.onloadend = (e) => {
      self.item.picture = myReader.result as any;
    }
    myReader.readAsDataURL(file);
  }

  async save(){
    await this.me.update(this.item);
    this.dismiss();
  }

  async logout(){
    const self = this;
    if(await self.app.confirm(`Sair`, `Você tem certeza de que deseja sair do sistema?`)){
      await self.me.logout();
      self.router.navigateByUrl('/login');
    }
  }
}
